.p-datatable .p-datatable-thead > tr > th {
  background-color: var(--darkSlateBlue);
  color: var(--white);
  border-color: var(--midnight);
  text-align: center;
  font-size: 15px;
}
.p-datatable .p-datatable-tbody > tr > td {
  background-color: var(--darkSlateBlue);
  color: var(--white);
  border-color: var(--midnight);
  text-align: center;
  font-size: 12px;
}

.datatable-responsive {
  .p-datatable-responsive {
    .p-datatable-tbody {
      > tr {
        > td {
          .p-column-title {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 728px) {
  .datatable-responsive {
    .p-datatable.p-datatable-responsive {
      .p-datatable-thead {
        > tr {
          > th {
            display: none !important;
          }
        }
      }
      .p-datatable-tfoot {
        > tr {
          > td {
            display: none !important;
          }
        }
      }
      .p-datatable-tbody {
        > tr {
          > td {
            text-align: left;
            display: block;
            width: 100%;
            float: left;
            clear: left;
            border: 0 none;
            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4em 1em -0.4em -0.4rem;
              font-weight: bold;
            }
            &:last-child {
              border-bottom: 1px solid var(--midnight);
            }
          }
        }
      }
    }
  }
}
